export const StaffData = [
  {
    id: 1,
    section: "Director's Office",
    staff: [
      {
        name: "Rahul Kumar",
        designation: "OSD to Director",
        mobile: "0657-23733922",
        email: "director#",
      },
      // {
      //   name: "Dr. Chintalacheruvu Madhusudana Rao",
      //   designation: "OSD to Director",
      //   mobile: "7870344602 , 7903964986",
      //   email: "cmrao.civil#",
      // },
      // {
      //   name: "Mr. Anirudha Sanyal",
      //   designation: "Senior Stenographer",
      //   mobile: "0657-2373407, 9470360235",
      //   email: "asanyal.adm#",
      // },
      {
        name: "Mr. Gopaljee Ram",
        designation: "Assistant SG-I",
        mobile: "9931180089",
        email: "gopaljee.adm#",
      },

      {
        name: "Mr. Charan Gorai",
        designation: "Office Attendant",
        mobile: "8877033294",
        email: "charan.mts#",
      },
      {
        name: "Mr. Bhaskar Pradhan",
        designation: "Office Attendant",
        mobile: "9162445028",
        email: "bhaskar.mts#",
      },
      // {
      //   name: "Mr. Dharmendra Mardi",
      //   designation: "Care Taker",
      //   mobile: "9162445028",
      //   email: "dmardi.adm#",
      // },
      {
        name: "Mr. Lakshman Singh",
        designation: "Driver",
        mobile: "8757727625",
        email: "lakshman.vehicle#",
      },
      // {
      //   name: "Mr. Ashok Kumar Sahu",
      //   designation: "Driver",
      //   mobile: "9939153699",
      //   email: "ashok.vehicle#",
      // },
      {
        name: "Mr. Suman Bharti",
        designation: "Driver",
        mobile: "9534004883",
        email: "suman.vehicle#",
      },
      {
        name: "Mr. Sanjay Kumar Sinha",
        designation: "Driver",
        mobile: "9279867270",
        email: "sanjay.vehicle#",
      },
      // {
      //     name: "Mr. Ashok Sahu",
      //     designation: "Driver",
      //     mobile: "",
      //     email: ""
      // },
      {
        name: "Mr. Nitish Das",
        designation: "Security Guard",
        mobile: "8873818291",
        email: "nitish.mts#",
      },
      {
        name: "Mr. Johri Sahani",
        designation: "Security Guard",
        mobile: "9973810431",
        email: "johri.security#",
      },
    ],
  },
  {
    id: 2,
    section: "Deputy Director's Office",
    staff: [
      {
        name: "Mr. Sanjay Chatterjee",
        designation: "Assistant SG-I",
        mobile: "9204873604",
        email: "schatterjee.adm#",
      },
    ],
  },

  {
    id: 3,
    section: "Registrar Office",
    staff: [
      {
        name: "Colonel Dr. Nisheeth Kumar Rai (Retd.)",
        designation: "Registrar",
        mobile: "0657-2373629(O) ,0657-2373246(Fax)",
        email: "registrar#",
      },
      // {
      //   name: "Mr. Ashutosh Ranjan",
      //   designation: "Assistant SG-II",
      //   mobile: "+91 6572373774",
      //   email: "oo.registrar#",
      // },
      // {
      //   name: "Mr. Vishal Kumar",
      //   designation: "Junior Assistant",
      //   mobile: "9931835178",
      //   email: "vishal.jras#",
      // },
      {
        name: "Mr. Manoj Kumar Jha",
        designation: "Office Attendant",
        mobile: "9470126910",
        email: "manoj.mts#",
      },
      {
        name: "Mr. Bablu Sardar",
        designation: "Office Attendant",
        mobile: "9122097726",
        email: "bablusardar.mts#",
      },
      {
        name: "Mr. Chandra Mohan Mukhi",
        designation: "Office Attendant",
        mobile: "9939882178",
        email: "chandramohan.mts#",
      },
      {
        name: "Mr. Anand Pradhan",
        designation: "Office Attendant",
        mobile: "8825128572",
        email: "anandpradhan.mts#",
      },
    ],
  },

  {
    id: 4,
    section: "Establishment",
    staff: [
      {
        name: "Mr. Sunil Kumar Bhagat",
        designation: "Assistant Registrar",
        mobile: "94311483138",
        email: "ar.establishment#",
      },
      {
        name: "Mr. Sumit Kr. Pandey",
        designation: "Superintendent",
        //mobile: "7667235313",
        email: "sumit.sup#",
      },
      // {
      //   name: "Mr. Kumar Shanu",
      //   designation: "Superintendent",
      //   mobile: "",
      //   // mobile: "8700410684",
      //   email: "kshanu.sup#",
      // },
      {
        name: "Mr. Arjun Singh",
        designation: "Assistant SG-II",
        mobile: "6299627202",
        email: "arjun.esta#",
      },
      //   {
      //     name: "Mr. Rajkumar Pramanik",
      //     designation: "Assistant SG-II",
      //     mobile: "7762836075",
      //     email: "rajkumar.esta#",
      //   },

      {
        name: "Mr. Navanit Ranjan Sahoo",
        designation: "Junior Assistant",
        mobile: "7544987682",
        email: "nrsahoo.jras#",
      },
      {
        name: "Mr. Vishal Kumar",
        designation: "Junior Assistant",
        mobile: "9931835178",
        email: "vishal.jras#",
      },
      {
        name: "Mr. Anup Kumar Mahato",
        designation: "Office Attendant",
        mobile: "9234410192",
        email: "anup.mts#",
      },
      {
        name: "Mr. Chhote Lal Soren",
        designation: "Office Attendant",
        mobile: "9508846955",
        email: "chhotelal.esta#",
      },
      {
        name: "Mr. Jyoti Lal Singh",
        designation: "Office Attendant",
        mobile: "",
        email: "jyotilal.esta#",
      },
      {
        name: "Mr. Bharat Singh",
        designation: "Office Attendant",
        mobile: "9525313833",
        email: "bharat.esta#",
      },
      //   {
      //     name: "Mr. Anil Kumar",
      //     designation: "Assistant SG-I",
      //     mobile: "9431387298",
      //     email: "anil.cc#",
      //   },
      //   {
      //     name: "Mr. Nand Kishore Jha",
      //     designation: "Assistant SG-I",
      //     mobile: "9204873604",
      //     email: "anil.cc#",
      //   },
    ],
  },

  {
    id: 5,
    section: "Office of Public Relation Officer",
    staff: [
      {
        name: "Mr. Sunil Kumar Bhagat",
        designation: "Assistant Registrar",
        mobile: "94311483138",
        email: "pro#",
      },
    ],
  },

  {
    id: 6,
    section: "Finance and Accounts Division",
    staff: [
      {
        name: "Mr. Munesh Kumar",
        designation: "Assistant Registrar (I/C)",
        mobile: "9307812117",
        email: "ar.fa#",
      },
      // {
      //   name: "Mr. Gopaljee Ram",
      //   designation: "Assistant Registrar (F&A)",
      //   mobile: "9931180089",
      //   email: "ar.fa#",
      // },
      {
        name: "Mr. Saikat Mridha",
        designation: "Senior Superintendent",
        mobile: "6200692336",
        email: "saikat.accountant#",
      },
      // {
      //   name: "Mr. Ankit Kumar",
      //   designation: "Superintendent",
      //   mobile: "9304651285",
      //   email: "ankit.sup#",
      // },
      // {
      //   name: "Mr. Suman Kumar",
      //   designation: "Assistant SG-I",
      //   mobile: "8051121074",
      //   email: "suman.fa#",
      // },
      {
        name: "Mr. Rajkumar Baitha",
        designation: "Assistant SG-II",
        mobile: "8210616178",
        email: "rkbaitha#",
      },
      // {
      //   name: "Mr. Vipul Kumar",
      //   designation: "Junior Assistant",
      //   mobile: "7004874513",
      //   email: "vipul.jras#",
      // },
      {
        name: "Mr.Ashutosh Singh",
        designation: "Junior Assistant",
        mobile: "7007674290",
        email: "Ashutosh.jras#",
      },
      // {
      //   name: "Mr. Janmajay Sardar",
      //   designation: "Security Guard",
      //   mobile: "6200290128",
      //   email: "janmejay.mts#",
      // },
      {
        name: "Mr. Giri Pradhan",
        designation: "Supporting Staff",
        mobile: "8340175903",
        email: "giri.mts#",
      },
    ],
  },

  {
    id: 7,
    section: "Store & Purchase",
    staff: [
      // {
      //   name: "Mr. NK Jha",
      //   designation: "A.R.(Store and Purchase)",
      //   mobile: "8340560817",
      //   email: "ar.sp#",
      // },
      // {
      //   name: "Mr. Prabhanshu Kumar",
      //   designation: "Superintendent",
      //   mobile: "8434631602",
      //   email: "pkumar.sup#",
      // },
      {
        name: "Mr. N.K. Jha",
        designation: "A.R.(Store and Purchase)",
        mobile: " ",
        email: "ar.sp#",
      },
      {
        name: "Mr. Prabhanshu Kumar",
        designation: "Superintendent",
        mobile: " ",
        email: "pkumar.sup#",
      },
      {
        name: "Mr. Raj Kumar Pramanik",
        designation: "Assistant SG-II",
        mobile: "7762836075",
        email: "rajkumar.esta#",
      },
      {
        name: "Mr. Matbar Sardar",
        designation: "Security Guard",
        mobile: "9905540758",
        email: "matbar.mts#",
      },
      {
        name: "Mr. Rahul Kumar",
        designation: "Superintendent",
        mobile: "06572373598",
        email: "rahul.sup#",
      },
      //   {
      //     name: "Mr. Rakesh Kumar",
      //     designation: "MIS/ERP Trainee",
      //     mobile: "",
      //     email: "rakesh.lib#",
      //   },
      //   {
      //     name: "Mr. Saurabh Saini",
      //     designation: "MIS/ERP Trainee",
      //     mobile: "",
      //     email: "saurabh.lib#",
      //   },
    ],
  },

  {
    id: 8,
    section: "Academic Office",
    staff: [
      {
        name: "Mr. Ram Pravesh Prasad",
        designation: "Assistant Registrar",
        mobile: "06572373598",
        email: "rpprasad.adm#",
      },
      {
        name: "Mr. Ashutosh Ranjan",
        designation: "Assistant SG-II",
        mobile: "",
        email: "aranjan.adm#",
      },
      {
        name: "Mr. Sudhanshu Shekhar",
        designation: "Assistant SG-II",
        mobile: "7903888794",
        email: "sudhanshu.adm#",
      },
      // {
      //   name: "Mr. Sanjay Chatterjee",
      //   designation: "Assistant SG-I(Fee Remission)",
      //   mobile: "9204873604",
      //   email: "schatterjee.adm#",
      // },
      // {
      //   name: "Mr. Tarun Kumar Kar",
      //   designation: "Assistant SG-I(Examination)",
      //   mobile: "9431752990",
      //   email: "",
      // },
      // {
      //   name: "Mr. Shailesh Kumar",
      //   designation: "Assitant SG-I",
      //   mobile: "9608700679",
      //   email: "shailesh.esta#",
      // },
      // {
      //   name: "Mr. Kumar Aditya",
      //   designation: "Junior Assistant",
      //   mobile: "",
      //   email: "kaditya.jras#",
      // },

      // {
      //   name: "Mr. Joydeep Ghosh",
      //   designation: "Junior Assistant",
      //   mobile: "7318832302",
      //   email: "joydeep.jras#",
      // },
      //   {
      //     name: "Mr. Amrit Kumar",
      //     designation: "Technical Assistant",
      //     mobile: "7870499520",
      //     email: "raviranjan.adm#",
      //   },
      // {
      //   name: "Mr. Shristidhar Mahto",
      //   designation: "Supporting Staff",
      //   mobile: "",
      //   email: "",
      // },
      {
        name: "Mr. Ruhi Manjhi",
        designation: "Supporting Staff",
        mobile: "9162029698",
        email: "ruhi.mts#",
      },
      {
        name: "Mr. Sunil Gour",
        designation: "Supporting Staff",
        mobile: "7781025637",
        email: "sunil.mts#",
      },
      {
        name: "Mr. Raghu Nath Mukhi",
        designation: "Supporting Staff",
        mobile: "8002485844",
        email: "raghunath.mts#",
      },
    ],
  },

  {
    id: 9,
    section: "Computer Centre",
    staff: [
      {
        name: "Mr. Tarkeshwar Kumar",
        designation: "Assistant(SG-I)",
        mobile: "8235314115 , 9709085514",
        email: "tarkeshwar.cc#",
      },
      //   {
      //     name: "Mr. Sudhir Kumar Sinha",
      //     designation: "Technicl Assistant",
      //     mobile: "",
      //     email: "",
      //   },
      {
        name: "Mr. Shankar Singh",
        designation: "Technical Assistant",
        mobile: "9471364206",
        email: "sankar.cc#",
      },
      //   {
      //     name: "Mr. Amrit Kumar",
      //     designation: "Tech Assistan",
      //     mobile: "7717790338",
      //     email: "amrit.cc#",
      //   },
      {
        name: "Mr. Ravi Ranjan",
        designation: "Technical Assistant",
        mobile: "7870499520",
        email: "raviranjan.adm#",
      },
      // {
      //   name: "Mr. Tileshwar Mahto ",
      //   designation: "Technical Assistant",
      //   mobile: "8755645615",
      //   email: "tileshwar.ta#",
      // },
      // {
      //   name: "Mr. Ratish Kumar Paswan",
      //   designation: "Technician",
      //   mobile: "7632974634",
      //   email: "ratish.te#",
      // },
      {
        name: "Mr. Abhishek Yadav",
        designation: "Technician",
        mobile: "6206806395",
        email: "abhishek.te#",
      },
      // {
      //   name: "Mr. Piyush Kumar",
      //   designation: "Technician",
      //   mobile: "7004190023",
      //   email: "piyush.te#",
      // },

      {
        name: "Mr. Aditya Mahali",
        designation: "Supporting Staff",
        mobile: "9065328629",
        email: "adityamahali.mts#",
      },
      {
        name: "Mr. Sandeep Kumar",
        designation: "Supporting Staff",
        mobile: "6202934350",
        email: "sandeep.mts#",
      },
      {
        name: "Mr. Santosh Sardar",
        designation: "Supporting Staff",
        mobile: "7091056982",
        email: "santosh.mts#",
      },
    ],
  },

  {
    id: 10,
    // section: "Placement Cell",
    section: "Training And Placement Cell",
    staff: [
      {
        name: "Mr. Sushil Kumar Gupta",
        designation: "Assistant SG-II",
        mobile: "7992321294",
        email: "sgupta.sp#",
      },
      //   {
      //     name: "Mr. Anil Kumar",
      //     designation: "Assistant SG-II",
      //     mobile: "",
      //     email: "",
      //   },
      {
        name: "Mr. Bhola Prasad Yadav",
        designation: "Security Guard",
        mobile: "9234716131",
        email: "bhola.mts#",
      },
    ],
  },

  {
    id: 11,
    section: "Institute Health Centre",
    staff: [
      // {
      //   name: "Dr. R.P. Singh",
      //   designation: "Chairman Medical Services",
      //   mobile: "9334622838",
      //   email: "chairman.medical#",
      // },
      {
        name: "Dr. MD. A. Hassan",
        designation: "Chairman Medical Services",
        mobile: "9334622838",
        email: "chairman.medical#",
      },
      {
        name: "Dr. Abhay Kumar",
        designation: "Medical Officer",
        mobile: "8757767348",
        email: "medicalofficer#",
      },
      // {
      //   name: "Dr. Aakanksha Sumedha",
      //   designation: "Medical Officer(F)",
      //   mobile: "7749849491",
      //   email: "",
      // },
      {
        name: "",
        designation: "Medical Officer(F)",
        mobile: "",
        email: "",
      },
      {
        name: "Mr. Anil Kumar",
        designation: "Assistant SG-II",
        mobile: "9431987298",
        email: "anil.cc#",
      },

      // {
      //   name: "Dr. Prakhar Tiwari",
      //   designation: "Medical Officer",
      //   mobile: "",
      //   email: "",
      // },
      // {
      //   name: "Mr. K P Dubey",
      //   designation: "Medical Assistant",
      //   mobile: "8002790710",
      //   email: "",
      // },
      {
        name: "",
        designation: "Medical Assistant",
        mobile: "",
        email: "",
      },
      {
        name: "Miss Aparna Mahato",
        designation: "Nurse(F)",
        mobile: "9798963206",
        email: "",
      },
      {
        name: "Miss Puja Kumari",
        designation: "DEO",
        mobile: "6200773639",
        email: "",
      },
      {
        name: "Mrs. Kanchan Devi",
        designation: "Care Taker",
        mobile: "7764879742",
        email: "kanchan.mts#",
      },
      {
        name: "Mr. Moti Lal Gour",
        designation: "Security Guard",
        mobile: "7645093150",
        email: "motilal.mts#",
      },
    ],
  },

  {
    id: 12,
    section: "Central Library",
    staff: [
      {
        name: "Mr. Munesh Kumar",
        designation: "Deputy Librarian",
        mobile: "+91 9307812117",
        email: "librarian#",
      },
      {
        name: "Dr. Neeta Bharti",
        designation: "Assistant Librarian",
        mobile: "+91 8986711008",
        email: "neetabharti.lib#",
      },
      // {
      //   name: "Mr. N.K. Sethy",
      //   designation: "Assistant Librarian",
      //   mobile: "+91-657-237-4165, 9470389685",
      //   email: "nksethi.lib#",
      // },
      // {
      //   name: "Mr. Umesh Kumar",
      //   designation: "Assistant Librarian",
      //   mobile: "+91-657-237-4167, 9470154510",
      //   email: "",
      // },
      {
        name: "Mr. Vikash Pratap Mahto",
        designation: "Office Attendant",
        mobile: "8434591567",
        email: "vijay.mts#",
      },
      {
        name: "Mr. Ganesh Shrestha",
        designation: "Care Taker",
        mobile: "9304800620",
        email: "ganesh.mts#",
      },
      {
        name: "Mr. Ravi Mahato",
        designation: "Attendant SG-II",
        mobile: "7294169042",
        email: "ravimahato.mts#",
      },

      {
        name: "Mr. Ramesh Ram",
        designation: "Security Guard",
        mobile: "9534003587",
        email: "rameshram.mts#",
      },
    ],
  },
  {
    id: 13,
    // section: "Chief Warden Office",
    section: "Chief Warden(Office)",
    staff: [
      // {
      //   name: "Mr. Arun Kumar Pandit",
      //   designation: "Assistant SG-II",
      //   mobile: "9955833605",
      //   email: "arun.fa#",
      // },
      {
        name: "Mrs. Bobby Banerjee",
        designation: "Assistant SG-II",
        mobile: "   ",
        email: "bbanerjee#",
      },
      {
        name: "Mr. Shailesh Kumar",
        designation: "Assitant SG-I",
        mobile: "9608700679",
        email: "shailesh.esta#",
      },
      {
        name: "Mr. Krishna Mukhi",
        designation: "Care Taker SG-II",
        mobile: "7061831934",
        email: "krishnamukhi.mts#",
      },
      {
        name: "Mr. Bideshi Mukhi-I",
        designation: "Care Taker",
        mobile: "9431304828",
        email: "bideshi1.mts#",
      },
      {
        name: "Mr. Mahesh Mukhi",
        designation: "Care Taker",
        mobile: "9934925857",
        email: "mahesh.mts#",
      },
      {
        name: "Mr. Bideshi Mukhi-II",
        designation: "Care Taker",
        mobile: "6204134028",
        email: "bideshi.mts#",
      },
      {
        name: "Smt. Saraswati Mukhi",
        designation: "Care Taker",
        mobile: "7004994849",
        email: "saraswati.mts#",
      },
      {
        name: "Mr. Murli Mahato",
        designation: "Office Attendant",
        mobile: "6202625938",
        email: "murli.security#",
      },
      {
        name: "Mr. Akhil Pradhan",
        designation: "Security Guard",
        mobile: "7091415187",
        email: "akhil.security#",
      },
      {
        name: "Mr. Kisto Manjhi",
        designation: "Security Guard",
        mobile: "6201299735",
        email: "kisto.security#",
      },
      {
        name: "Mr. Dayamay Prajapati",
        designation: "Security Guard",
        mobile: "8102381067",
        email: "dayamay.security#",
      },
      {
        name: "Mrs. Sumitra Pradhan",
        designation: "Attendant",
        mobile: "    ",
        email: "sumitra#",
      },
    ],
  },

  {
    id: 14,
    section: "Hostels(Supervisor)",
    staff: [
      {
        name: "Mrs. Punam Singh",
        designation: "Hostel-(RLB, Ambedkar)",
        mobile: "6207564721",
        email: "hostel.rlb#,hostel.ahr#",
      },
      {
        name: "Mrs. Seema Devi",
        designation: "Hostel-(A, B)",
        mobile: "6207362023",
        email: "hostel.a#,hostel.b#",
      },
      {
        name: "Mrs. Puja Kumari",
        designation: "Hostel-(C, D)",
        mobile: "8092777565",
        email: "hostel.c#,hostel.d#",
      },
      {
        name: "Mr. Binay Kumar Ojha",
        designation: "Hostel-(E, H)",
        mobile: "8053535872",
        email: "hostel.e#,hostel.h#",
      },
      {
        name: "Mr. Panchanand Jha",
        designation: "Hostel-(F, G)",
        mobile: "7979030198",
        email: "hostel.f#,hostel.g#",
      },
      {
        name: "Mr. Vikash Kumar Rai",
        designation: "Hostel-I",
        mobile: "7020754832",
        email: "hostel.i#",
      },
      {
        name: "Mr. Santosh Kumar Singh",
        designation: "Hostel-J",
        mobile: "7004114283",
        email: "hostel.j#",
      },
      {
        name: "Mr. Mukesh Kumar",
        designation: "Hostel-K",
        mobile: "8351927920",
        email: "hostel.k#",
      },
    ],
  },

  {
    id: 15,
    section: "Civil Engineering",
    staff: [
      // {
      //   name: "Mr. Niranjan Kumar",
      //   designation: "Technical Assistant",
      //   mobile: "",
      //   email: "niranjan.ta#",
      // },
      {
        name: "Mr. Suraj Kushwaha",
        designation: "Technical Assistant",
        mobile: "7906591370",
        email: "suraj.ta#",
      },
      {
        name: "Mr. Arnab Hati",
        designation: "Senior Technician",
        mobile: "8537957448",
        email: "arnabhati.st#",
      },
      {
        name: "Mr. Sougat Maji",
        designation: "Technician",
        mobile: "9523507409",
        email: "sougat.te#",
      },
      {
        name: "Mr. Arnav Munda",
        designation: "Technician",
        mobile: "8252192233",
        email: "arnavmunda.te#",
      },
      {
        name: "Mr. Damu Tiu",
        designation: "Office Attendant SG-II",
        mobile: "7761824861",
        email: "damu.mts#",
      },
      {
        name: "Mr. Basu Gour",
        designation: "Office Attendant",
        mobile: "9431327698",
        email: "basu.mts#",
      },

      // {
      //   name: "Ms. Sachini Devi",
      //   designation: "Supporting Staff",
      //   mobile: "7781025637",
      //   email: "sachini.mts#",
      // },
    ],
  },

  {
    id: 16,
    section: "Computer Science & Engineering",
    staff: [
      {
        name: "Mr. Sudhir Kumar Sinha",
        designation: "Sr. Technical Assistant",
        mobile: "8986811579",
        email: "sudhir.adm#",
      },
      {
        name: "Mr. Ramesh Kumar Rathour",
        designation: "Sr. Technician",
        mobile: "7428909054",
        email: "rameshrathour.st#",
      },

      //   {
      //     name: "Mr. Ravi Ranjan",
      //     designation: "Technical Assistant",
      //     mobile: "",
      //     email: "raviranjan.adm#",
      //   },
      //   {
      //     name: "Mr. Ratish Kumar Paswan",
      //     designation: "Technician",
      //     mobile: "",
      //     email: "ratish.tec#",
      //   },
      {
        name: "Ms. Preety Manonit Dodray",
        designation: "Technician",
        mobile: "8210642339",
        email: "preety.te#",
      },
      {
        name: "Mr. Debashish Das",
        designation: "Technician",
        mobile: "6371699509",
        email: "debashis.te#",
      },
      // {
      //   name: "Mr. Anand Pradhan",
      //   designation: "Office Attendant",
      //   mobile: "8825128572",
      //   email: "anandpradhan.mts#",
      // },
      {
        name: "Mrs. Sugga Devi",
        designation: "Office Attendant",
        mobile: "8227021541",
        email: "sugga.mts#",
      },
      {
        name: "Mr. Ramesh Mukhi",
        designation: "Care Taker SG-II",
        mobile: "8789312897",
        email: "ramesh.mts#",
      },
    ],
  },

  {
    id: 17,
    section: "Electrical Engineering",
    staff: [
      {
        name: "Mr. Rupak Kumar",
        designation: "Senior Technical Officer",
        mobile: "",
        email: "rupakkumar.ws#",
      },
      // {
      //   name: "Mr. Sudhanshu Shekhar",
      //   designation: "Assistant SG-II",
      //   mobile: "7903888794",
      //   email: "sudhanshu.adm#",
      // },
      {
        name: "Mr. MD. Sazzad",
        designation: "Technical Assistant",
        mobile: "7992388150",
        email: "sazzad.ta#",
      },
      {
        name: "Mr. Nara Yuvakiran",
        designation: "Technical Assistant",
        mobile: "7989172097",
        email: "nyuvakiran.ta#",
      },
      {
        name: "Mr. Jay Banerjee",
        designation: "Senior Technician",
        mobile: "8102820831",
        email: "jay.st#",
      },
      {
        name: "Mr. Sanjeev Kumar",
        designation: "Technician SG-II",
        mobile: "7488177237",
        email: "sanjeev.adm#",
      },
      {
        name: "Mr. Ujjwal Panigrahi",
        designation: "Technician",
        mobile: "8409673227",
        email: "ujjwal.te#",
      },
      {
        name: "Mr. Aniket Samanta",
        designation: "Technician",
        mobile: "6291399617",
        email: "aniket.te#",
      },
      {
        name: "Mrs. Kanak Kumari",
        designation: "Junior Assistant",
        mobile: "    ",
        email: "kanak.esta#",
      },

      // {
      //   name: "Mr. Shyam Sundar Mandal",
      //   designation: "Supporting Staff",
      //   mobile: "",
      //   email: "",
      // },
      {
        name: "Mr. Mangal Mukhi",
        designation: "Care Taker",
        mobile: "9122588728",
        email: "mangal.mts#",
      },
      {
        name: "Mr. Krishna Rajak",
        designation: "Office Attendant SG-I",
        mobile: "8092798497",
        email: "krishna.mts#",
      },
      {
        name: "Mr. Guruva Mukhi",
        designation: "Office Attendant",
        mobile: "9934911227",
        email: "guruva.mts#",
      },
    ],
  },

  {
    id: 18,
    section: "Electronics & Communication Engineering",
    staff: [
      {
        name: "Mr. Reyan Akhtar",
        designation: "Technical Assitant",
        mobile: "8969789986",
        email: "reyanakhtar.ta#",
      },
      {
        name: "Mr. Chandra Bhanu",
        designation: "Senior Technician",
        mobile: "8700745117",
        email: "chandra.st#",
      },
      {
        name: "Mr. Sikandar Kumar Sah",
        designation: "Technician",
        mobile: "8406011502",
        email: "sikandarsah.te#",
      },
      {
        name: "Mr. Subodh Kumar",
        designation: "Technician",
        mobile: "7061979071",
        email: "subodh.te#",
      },
      {
        name: "Mr. Makhan Lal",
        designation: "Office Attendant",
        mobile: "6207020938",
        email: "makhan.mts#",
      },
      {
        name: "Mr. Ravi Mukhi(2)",
        designation: "Care Taker",
        mobile: "9546193106",
        email: "ravimukhi.mts#",
      },
    ],
  },

  {
    id: 19,
    section: "Humanities, Social Science Management",
    staff: [
      {
        name: "Mr. Bir Prasad Mukhi",
        designation: "Care Taker SG-II",
        mobile: "9199102120",
        email: "bmukhi.mts#",
      },
      // {
      //   name: "Mr. Bachhu Jha",
      //   designation: "Supporting Staff",
      //   mobile: "",
      //   email: "",
      // },
      // {
      //   name: "Mr. Hagru Mukhi",
      //   designation: "Care Taker",
      //   mobile: "620906274",
      //   email: "hagru.mts#",
      // },
    ],
  },

  {
    id: 20,
    section: "Central Workshop",
    staff: [
      // {
      //   name: "Mr. Rupak Kumar",
      //   designation: "Senior Technical Officer",
      //   mobile: "",
      //   email: "rupakkumar.ws#",
      // },
      {
        name: "Mr. Anup Chhabi Topno",
        designation: "Technical Officer",
        mobile: "",
        email: "actopno#",
      },
      {
        name: "Mr. Girija Nandan Arka",
        designation: "Technical Assitant",
        mobile: "9438381972",
        email: "girija.ta#",
      },
      // {
      //   name: "Mr. Naresh Kumar Pathak",
      //   designation: "Assistant SG-I",
      //   mobile: "8825115393",
      //   email: "nkpathak.ws#",
      // },
      {
        name: "Mr. Gupteshwar Prasad",
        designation: "Senior Technician",
        mobile: "9572637394",
        email: "gupteshwar.ws#",
      },
      {
        name: "Mr. Satdev Singh",
        designation: "Senior Technician",
        mobile: "",
        email: "",
      },
      {
        name: "Mr. Ravikar Kumar",
        designation: "Technician",
        mobile: "8292628087",
        email: "ravikar.te#",
      },
      // {
      //   name: "Mr. Kashi Prasad",
      //   designation: "Supporting Staff",
      //   mobile: "",
      //   email: "",
      // },
      {
        name: "Mr. Dilip Mahali",
        designation: "Supporting Staff",
        mobile: "9153443809",
        email: "dilip.mts#",
      },
    ],
  },

  {
    id: 21,
    section: "Production & Industrial Engineering",
    staff: [
      // {
      //   name: "Mr. Banshi Lal Sardar",
      //   designation: "Assistant SG-I",
      //   mobile: "9572387826",
      //   email: "banshilal#",
      // },
      //   {
      //     name: "Mohd. Iqbal Ansari",
      //     designation: "Technical Assistant",
      //     mobile: "",
      //     email: "",
      //   },
      // {
      //   name: "Mr. Girija Nandan Arka",
      //   designation: "Technical Assitant",
      //   mobile: "9438381972",
      //   email: "girija.ta#",
      // },
      {
        name: "Mr. Manish Kant",
        designation: "Senior Technician",
        mobile: "9471311222",
        email: "manish.st#",
      },
      {
        name: "Mr. Ravikar Kumar",
        designation: "Technician",
        mobile: "8292628087",
        email: "ravikar.te#",
      },
      {
        name: "Mr. Govind Mandi",
        designation: "Technician",
        mobile: "8340442296",
        email: "govind.te#",
      },
      {
        name: "Mr. Pullin Kumar Dutta",
        designation: "Office Attendant",
        mobile: "8002101482",
        email: "pulin.mts#",
      },
      {
        name: "Mr. Dilip Mahali",
        designation: "Office Attendant",
        mobile: "9153443809",
        email: "dilip.mts#",
      },
    ],
  },

  {
    id: 22,
    section: "Metallurgical & Materials Engineering",
    staff: [
      // {
      //   name: "Mr. Sanjay Chatterjee",
      //   designation: "Assistant SG-I",
      //   mobile: "",
      //   email: "",
      // },
      // {
      //   name: "Mr. Arnab Sasamal",
      //   designation: "Technical Assistant",
      //   mobile: "",
      //   email: "",
      // },
      {
        name: "Mr. Kunjan Kumar",
        designation: "Technical Assistant",
        mobile: "9155158274",
        email: "kunjan.ta#",
      },
      {
        name: "Mr. Lokanath Mohanty",
        designation: "Technical Assistant",
        mobile: "7870153346",
        email: "lokanath.ta#",
      },
      {
        name: "Mr. Ujjawal Pathak",
        designation: "Senior Technician",
        mobile: "9806312219",
        email: "ujjwal.st#",
      },
      {
        name: "Mr. Shashikant Behera",
        designation: "Technician",
        mobile: "8249542918",
        email: "shashikant.te#",
      },
      {
        name: "Mr. Himanshu kumar",
        designation: "Technician",
        mobile: "9934585149",
        email: "himanshu.ta#",
      },
      {
        name: "Mr. Kameshwar Roy",
        designation: "Office Attendant",
        mobile: "8292086742",
        email: "kameshwar.security#",
      },
      {
        name: "Mr. Kamal Kant Das",
        designation: "Security Guard",
        mobile: "8002343063",
        email: "kamalkant.security#",
      },
      // {
      //   name: "Mr. Deenanath Prasad",
      //   designation: "Supporting Staff",
      //   mobile: "",
      //   email: "",
      // },
    ],
  },

  {
    id: 23,
    section: "Mechanical Engineering",
    staff: [
      {
        name: "Mr. Ganga Ram Mandal",
        designation: "Technical Assistant",
        mobile: "9199992132",
        email: "gmandal.ta#",
      },
      {
        name: "Mr. Rahul Mishra",
        designation: "Technical Assistant",
        mobile: "7903776966",
        email: "rahul.ta#",
      },
      {
        name: "Mr. Sourav Garai",
        designation: "Senior Technician",
        mobile: "7358634443",
        email: "sourav.st#",
      },
      {
        name: "Mr. Sanjay Kumar",
        designation: "Sr. Technician (SG-II)",
        mobile: "8863041907",
        email: "sanjay.adm#",
      },
      {
        name: "Mr. Aditya Dhanraj",
        designation: "Technician",
        mobile: "8292313629",
        email: "aditya.te#",
      },
      {
        name: "Mr. Ravi Shankar Kumar",
        designation: "Technician",
        mobile: "8340475457",
        email: "rkumar.te#",
      },
      {
        name: "Mr. Arjun Mukhi",
        designation: "Care Taker",
        mobile: "8271794056",
        email: "arjun.mts#",
      },
      {
        name: "Mr. Suresh Pradhan",
        designation: "Office Attendant",
        mobile: "9801339006",
        email: "suresh.mts#",
      },
    ],
  },

  {
    id: 24,
    section: "Mathematics",
    staff: [
      {
        name: "Mr. Vibhas Kumar Sinha",
        designation: "Senior Technician",
        mobile: "8797070465",
        email: "vsinha.st#",
      },
      {
        name: "Mr. Bir Prashad Mukhi",
        designation: "Care Taker SG-II",
        mobile: "9199102120",
        email: "bmukhi.mts#",
      },
      //   {
      //     name: "Mr. Sailesh Kumar",
      //     designation: "SG-I",
      //     mobile: "9608700679",
      //     email: "",
      //   },
      //   {
      //     name: "Mr. Birprashad Mukhi",
      //     designation: "Assistant",
      //     mobile: "9199102120",
      //     email: "",
      //   },
    ],
  },

  {
    id: 25,
    section: "Physics",
    staff: [
      {
        name: "Mr. Chandan Kumar Rahtey",
        designation: "Technical Assistant",
        mobile: "9078063785",
        email: "chandan.ta#",
      },
      {
        name: "Mr. Sumit Sehgal",
        designation: "Technician",
        mobile: "7033564467",
        email: "sumit.te#",
      },
      {
        name: "Mr. Mihir Chandra Pradhan",
        designation: "Supporting Staff",
        mobile: "9162005112",
        email: "mihir.security#",
      },
    ],
  },
  {
    id: 26,
    section: "Chemistry",
    staff: [
      {
        name: "Mr. Biplab Koley",
        designation: "Technical Assitant",
        mobile: "9748683432",
        email: "biplab.ta#",
      },
      {
        name: "Mr. Sayan Chakraborty",
        designation: "Technician",
        mobile: "9547327093",
        email: "sayan.te#",
      },
      {
        name: "Smt. Sachini Devi",
        designation: "Care Taker",
        mobile: "9155136143",
        email: "sachini.mts#",
      },
      //   {
      //     name: "Mr. Rahul Roy",
      //     designation: "Senior Techinician",
      //     mobile: "",
      //     email: "rahul.st#",
      //   },
      //   {
      //     name: "Mr. Sachini Devi",
      //     designation: "Supporting Staff",
      //     mobile: "7781025637",
      //     email: "",
      //   },
    ],
  },

  {
    id: 27,
    section: "Vehicle",
    staff: [
      {
        name: "Mr. Lakshman Kumar Singh",
        designation: "Care Taker",
        mobile: "",
        email: "lakshman.vehicle#",
      },
      // {
      //   name: "Mr. Kamesh Prasad Singh",
      //   designation: "Driver",
      //   mobile: "",
      //   email: "",
      // },
      // {
      //   name: "Mr. Ashok Kumar Sahu",
      //   designation: "Supporting Staff",
      //   mobile: "",
      //   email: "",
      // },
      {
        name: "Mr. Bahadur Mahato",
        designation: "Office Attendant",
        mobile: "",
        email: "bahadur.mts#",
      },
    ],
  },

  {
    id: 28,
    section: "Planning and Devlopment",
    staff: [
      // {
      //   name: "Mr. Chandan Kumar Singh",
      //   designation: "Junior Engineer(Civil)",
      //   mobile: "7210482563",
      //   email: "chandan.je#",
      // },

      {
        name: "Mr. Rahul Kumar",
        designation: "Junior Engineer(Civil)",
        mobile: "7488575770",
        email: "rahul.je#",
      },
      {
        name: "Mr. Ahmed Shahnawaz",
        designation: "Junior Engineer(Electrical)",
        mobile: "7979758572",
        email: "ahmed.je#",
      },
      {
        name: "Mr. Angshuman Santra",
        designation: "Junior Engineer(Electrical)",
        mobile: "6290572027",
        email: "angshuman.je#",
      },
      // {
      //   name: "Mr. Sanjay Kumar",
      //   designation: "Sr. Technician (SG-II)",
      //   mobile: "8863041907",
      //   email: "sanjay.adm#",
      // },
      {
        name: "Mr. Dashrath Singh",
        designation: "Assistant (SG-II)",
        mobile: "   ",
        email: "dashrath.esta#",
      },
      // {
      //   name: "Mr. Vikash Kumar Sinha",
      //   designation: "Junior Assistant",
      //   mobile: "7992307191, 9570136351",
      //   email: "vikash.jras#",
      // },
      {
        name: "Mr. Mani Lal Yadav",
        designation: "Office Attendant SG-I",
        mobile: "9507584592",
        email: "manilal.mts#",
      },
      {
        name: "Mr. Ram Awadh Ram",
        designation: "Office Attendant",
        mobile: "9204647706",
        email: "ramawadh.mts#",
      },
    ],
  },
  {
    id: 29,
    section: "Student Activity Section ",
    staff: [
      // {
      //   name: "Mr. Jagdish Singh",
      //   designation: "Sr. SAS Assistant",
      //   mobile: "9199959767",
      //   email: "jagdish#",
      // },
      {
        name: "Mr. Provat Kr Roy",
        designation: "SAS Assistant",
        mobile: "9836353526",
        email: "provat.sas#",
      },
      {
        name: "Mr. Fantu",
        designation: "Office Attendant",
        mobile: "9110186471",
        email: "fantu.mts#",
      },
    ],
  },
  {
    id: 30,
    section: "Dean (Students' Welfare) ",
    staff: [
      {
        name: "Mr. Bipin Kumar Sinha",
        designation: "Assistant SG-II",
        mobile: "",
        email: "bipinkumarsinha.adm#",
      },
      {
        name: "Mr. Prabhat Kumar",
        designation: "Office Attendant",
        mobile: "9471137470",
        email: "prabhat.mts#",
      },
      {
        name: "Mrs. Sumitra Pradhan",
        designation: "Attendant",
        mobile: "    ",
        email: "sumitra#",
      },
    ],
  },
  {
    id: 31,
    section: "CPIO",
    staff: [
      // {
      //   name: "Mr. Kumar Aditya",
      //   designation: "Junior Assistant",
      //   mobile: "9123476192",
      //   email: "kaditya.jras#",
      // },
      {
        name: "Mr. Praful Kumar",
        designation: "DEO",
        mobile: "",
        email: "",
      },
    ],
  },
  {
    id: 32,
    section: "Dean (I & AR) & Dean (FW)",
    staff: [
      {
        name: "Dr. Syed Azmat Hussain",
        designation: "Deputy Registrar",
        mobile: "",
        email: "sazmat.dr#",
      },
      {
        name: "Mrs. Sumitra Pradhan",
        designation: "Attendant",
        mobile: "    ",
        email: "sumitra#",
      },
    ],
  },
  {
    id: 33,
    section: "Dean (R&C)",
    staff: [
      {
        name: "Mr. Subhas Kumar",
        designation: "Junior Assistant",
        mobile: "7488714707",
        email: "skumar.jras#",
      },
      // {
      //   name: "Mr. Vijay Pratap Mahato",
      //   designation: "Office Attendant",
      //   mobile: "8434591567",
      //   email: "vijay.mts#",
      // },
      {
        name: "Mrs. Sumitra Pradhan",
        designation: "Attendant",
        mobile: "    ",
        email: "sumitra#",
      },
    ],
  },
  {
    id: 34,
    section: "House Keeping & Green Campus",
    staff: [
      {
        name: "Mr. Surya Narayan Yadav",
        designation: "Assistant SG-II",
        mobile: "8603194011",
        email: "snyadav#",
      },
      {
        name: "Mr. Hagru Mukhi",
        designation: "Care Taker SG-II",
        mobile: "620906274",
        email: "hagru.mts#",
      },
      {
        name: "Mr. Ravi Mukhi-I",
        designation: "Care Taker",
        mobile: "8252719348",
        email: "ravimukhi1.mts",
      },
      {
        name: "Mr. Bihari Mukhi",
        designation: "Care Taker",
        mobile: "9572640756",
        email: "bihari.mts#",
      },
      {
        name: "Mr. Khokha Ram Mahato",
        designation: "Care Taker",
        mobile: "8252949133",
        email: "khokha.mts#",
      },
      {
        name: "Smt. Devi Mukhi",
        designation: "Care Taker",
        mobile: "9304629431",
        email: "devi.mts#",
      },
      {
        name: "Smt. Shanti Mukhi",
        designation: "Care Taker",
        mobile: "7033975030",
        email: "shanti.mts#",
      },
      {
        name: "Mr. Basant Mukhi ",
        designation: "Care Taker",
        mobile: "7004994849",
        email: "basant.mts#",
      },
    ],
  },
  {
    id: 35,
    section: "Electrical Maintenance Unit",
    staff: [
      {
        name: "Mr. Bankey Mishra",
        designation: "Office Attendant",
        mobile: "7739819914",
        email: "bankey.mts#",
      },
      {
        name: "Mr. Suresh Pradhan",
        designation: "Office Attendant",
        mobile: "9801339006",
        email: "suresh.mts#",
      },
    ],
  },
  {
    id: 36,
    section: "Security Control unit",
    staff: [
      {
        name: "Mr. Johri Sahani",
        designation: "Security Guard",
        mobile: "9973810431",
        email: "johri.security#",
      },
      {
        name: "Mr. Suraj Kumar",
        designation: "Security Guard",
        mobile: "7870481375",
        email: "suraj.security#",
      },
      {
        name: "Mr. Mathura Sardar",
        designation: "Security Guard",
        mobile: "6204472328",
        email: "mathura.security#",
      },
      {
        name: "Mr. Tapan Mahato",
        designation: "Security Guard",
        mobile: "7667955935",
        email: "tapan.security#",
      },
      {
        name: "Mr. Awadhesh Rajak",
        designation: "Office Attendant",
        mobile: "9955240756",
        email: "awadhesh.security#",
      },
      {
        name: "Mr. Sarbeshwar Mukhi",
        designation: "Office Attendant",
        mobile: "9939029089",
        email: "sarveshwar.mts#",
      },
    ],
  },
];
