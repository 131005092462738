import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Container from "../../../../../Components/UI/Container";
import Heading from "../../../../../Components/Heading";
import { LongPara } from "../../../styles";
// import { getRequest } from "../../../../../functions/request";
import { Helmet } from "react-helmet";


const CCHome = () => {
  const { t } = useTranslation();

  // const [computerCenterData, setComputerCenterData] = useState([]);

  // let computerCenterHandler = async () => {
  //   try {
  //     const res = await getRequest(`/central_facilities?facility=cc`);
  //     // setComputerCenterData(res.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   computerCenterHandler();
  // }, []);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="One of the attractions of this campus is the OVAL structure - the Computer Centre, which accommodates central computing facilities. There are mainly three types of services viz. computational services, laboratory services and network services. The Centre's services are extensively used by the students, faculty and staff members of the Institute."
        />
        <meta
          name="keywords"
          content="nit jamshedpur,nit jsr,nit,jamshedpur,technology,Director, conferences ,meeting,labs,laboratories,computers,stafflist,facility,conferences,software,hardware,Computer center,services,manthan,mis,server room,networking"
        />
      </Helmet>
      <Container width="1250px">
        <br />
        <LongPara style={{ textAlign: "justify" }}>
          {t("central_facilities_computer_center_body")}
        </LongPara>


        <Heading
          content={t("central_facilities_computer_center_services_heading")}
          style={{
            fontSize: "1.5em",
            marginBottom: "20px",
            marginTop: "13px",
            fontWeight: "500",
            background: "#274187",
            color: "#fff",
            padding: "1% 1% 1% 3%",
          }}
        />
        <LongPara
          dangerouslySetInnerHTML={{
            __html: t("central_facilities_computer_center_services"),
          }}
          style={{ textAlign: "justify", fontSize: "1em", lineHeight: "1.6" }}
        />
    
      </Container>
    </>
  );
};

export default CCHome;
